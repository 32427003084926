import AuthConfig from './AuthConfig'
import { EpisodeMetadata, Track } from '../App'

const PODCAST_PRESET = 'e5gH2o76AeuyyFBYv3r4Gi'

export default class AuphonicAPI {
    static async upload(tracks: Track[], metadata: EpisodeMetadata) {
        let uuid = await fetch(
            AuthConfig.api.endpoints.auphonic + '/productions.json',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + AuthConfig.auphonic.connection_string,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'preset': PODCAST_PRESET,
                    'is_multitrack': true,
                    'metadata': metadata,
                    'multi_input_files': tracks.filter(track => track.path).map(track => ({
                        'input_file': track.path,
                        'type': track.type,
                        'offset': track.offset
                    }))
                })
            }
        )
        .then(response => response.json())
        .then(json => json.data.uuid)
        .catch(alert)
        const data = new FormData()
        tracks.filter(track => track.blob).forEach(track => data.append(track.id!, track.blob!, `${track.id}.webm`))
        await fetch(AuthConfig.api.endpoints.auphonic + `/production/${uuid}/upload.json`, {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + AuthConfig.auphonic.connection_string
            },
            body: data
        }).catch(alert)
        await fetch(AuthConfig.api.endpoints.auphonic + `/production/${uuid}/start.json`, {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + AuthConfig.auphonic.connection_string
            },
        }).catch(alert)
    }
}