import React from 'react'
import './App.css'
import logo from './assets/logo.png'
import * as MaterialIcons from 'react-icons/md'
import { IconType } from 'react-icons/lib'
import AuphonicAPI from './api/AuphonicAPI'
import API from './api/APIClient'

const RoundButton = (params: { icon: IconType, onClick: React.MouseEventHandler<HTMLButtonElement>, size?: number, color?: string }) => <button className='round' onClick={params.onClick} style={{ width: params.size || 70, height: params.size || 70, fontSize: params.size! / 2 || 35, color: params.color }}>{<params.icon />}</button>

type State = 'welcome' | 'initialize' | 'new' | 'settings' | 'record' | 'pause' | 'finalize' | 'upload' | 'done'
type Channel = {
  id: string
  label: string
  gain: GainNode
  analyzer: AnalyserNode
  splitter?: ChannelSplitterNode
  output: MediaStreamAudioDestinationNode
  recorder?: MediaRecorder
  buffer?: BlobPart[]
}
export type Track = {
  id?: string
  blob?: Blob
  path?: string
  type: 'multitrack' | 'intro' | 'outro' | 'insert'
  offset?: number
}
export type SessionMetadata = {
  id: string
  title: string
  description: string
  chapters: number
}
export type EpisodeMetadata = {
  title?: string
  summary?: string
  album?: string
  track?: number
}

const context = new AudioContext({ sampleRate: 96000 })
var microphone: MediaStreamAudioSourceNode
const splitter = context.createChannelSplitter(2)
var channels = [
  { id: 'Track 1', label:'מיקרופון 1' },
  { id: 'Track 2', label:'מיקרופון 2' },
].map<Channel>(data => ({
  id: data.id,
  label: data.label,
  gain: context.createGain(),
  analyzer: context.createAnalyser(),
  splitter: context.createChannelSplitter(2),
  output: context.createMediaStreamDestination(),
  recorder: undefined,
  buffer: [] as BlobPart[]
}))
var tracks: Track[] = [
  {
    path: 'https://yk8recordings.blob.core.windows.net/audio/intro.mp3',
    type: 'intro'
  },
  {
    path: 'https://yk8recordings.blob.core.windows.net/audio/outro.mp3',
    type: 'outro'
  }
]
const playback = {
  merger: context.createChannelMerger() as ChannelMergerNode,
  gain: context.createGain(),
  output: context.createMediaStreamDestination(),
  recorder: undefined as MediaRecorder | undefined,
  buffer: [] as BlobPart[],
  blob: undefined as Blob | undefined
}

function App() {
  const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([])
  const [selectedDevice, setSelectedDevice] = React.useState<string | undefined>(undefined)
  const [state, setState] = React.useState<State>('welcome')
  const [waveforms, setWaveforms] = React.useState([new Uint8Array(channels[0].analyzer.fftSize), new Uint8Array(channels[1].analyzer.fftSize)])
  const [duration, setDuration] = React.useState(0)
  const durationHandle = React.useRef<NodeJS.Timeout>()
  const [episode, setEpisode] = React.useState<EpisodeMetadata>()
  const [sessions, setSessions] = React.useState<SessionMetadata[]>([])
  const player = React.useRef<HTMLAudioElement>(null)
  const forceUpdate = React.useReducer(x => x + 1, 0)[1]
  const init = React.useRef(true)

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedDevice(e.target.value)
  }

  async function getInput(deviceId: string) {
    return await navigator.mediaDevices.getUserMedia({
      video: false, audio: {
        deviceId: deviceId,
        latency: 0,
        echoCancellation: false,
        channelCount: 2,
        sampleRate: context.sampleRate
      }
    })
  }

  function startIntro() {
    channels.forEach(channel => {
      toggleMute(channel, 'mute')
      channel.recorder!.pause()
    })
    let introIndex = tracks.findIndex(track => track.type === 'intro')
    tracks[introIndex].type = 'insert'
    tracks[introIndex].offset = duration
    player.current!.src = tracks[introIndex].path!
    player.current!.onended = endIntro
    player.current!.play()
  }

  function endIntro() {
    channels.forEach(channel => {
      channel.recorder!.resume()
      toggleMute(channel, 'unmute')
    })
  }

  function toggleMute(channel: Channel, mode?: 'mute' | 'unmute') {
    if (mode === 'mute' || channel.gain.gain.value > 0.001)
      channel.gain.gain.exponentialRampToValueAtTime(0.0001, context.currentTime + 0.5)
    else if (mode === 'unmute' || channel.gain.gain.value <= 0.001)
      channel.gain.gain.exponentialRampToValueAtTime(channel.gain.gain.defaultValue, context.currentTime + 1)
  }

  React.useEffect(() => setSelectedDevice(devices[0]?.deviceId), [devices])

  React.useEffect(() => {(async () => {
    switch (state) {
      case 'initialize':
        if (!init.current) break
        init.current = false
        // Fetch podcasts list
        let list = await Promise.all((await API.select(`/crfd1_sessions?$select=crfd1_sessionid,crfd1_title,crfd1_description&$filter=Microsoft.Dynamics.CRM.ContainValues(PropertyName='crfd1_platform',PropertyValues=%5B'648970001'%5D)&$orderby=crfd1_title`, 'list') as [])
        .map<Promise<SessionMetadata>>(async session => ({
          id: session['crfd1_sessionid'],
          title: session['crfd1_title'],
          description: session['crfd1_description'],
          chapters: (await API.select(`/crfd1_episodes?$select=crfd1_episodeid&$filter=_crfd1_session_value eq ${session['crfd1_sessionid']}`, 'list') as []).length
        })))
        setSessions(list)
        // Retreive audio intefaces list
        if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
          navigator.mediaDevices.enumerateDevices().then(list => setDevices(list.slice(2)))
        }
        else
          alert("getUserMedia() is not supported by your browser")
        // Create & route audio nodes
        if (context.state === 'suspended')
          await context.resume()
        microphone = context.createMediaStreamSource(await getInput(selectedDevice!))
        microphone.connect(splitter)
        channels.forEach((channel, index) => {
          channel.analyzer.fftSize = 1024
          channel.gain.channelInterpretation = 'speakers'
          splitter.connect(channel.gain, index).connect(channel.analyzer).connect(channel.output)
          channel.gain.connect(playback.merger, 0, index)
          channel.recorder = new MediaRecorder(channel.output.stream, { mimeType: 'audio/webm', audioBitsPerSecond: context.sampleRate })
          channel.recorder.ondataavailable = e => channel.buffer!.push(e.data)
        })
        playback.gain.gain.value = 1.33
        playback.output.channelCount = 1
        let source = context.createMediaElementSource(player.current!)
        source.connect(playback.merger, 0, channels.length).connect(playback.gain).connect(playback.output)
        source.connect(context.destination)
        playback.recorder = new MediaRecorder(playback.output.stream, { mimeType: 'audio/webm', audioBitsPerSecond: context.sampleRate })
        playback.recorder.ondataavailable = e => playback.buffer!.push(e.data)
        // Update visualization data
        setInterval(() => {
          let waveforms: Uint8Array[] = []
          channels.forEach((channel, index) => {
            waveforms.push(new Uint8Array(channel.analyzer.frequencyBinCount))
            channel.analyzer.getByteTimeDomainData(waveforms[index])
          })
          setWaveforms(waveforms)
          forceUpdate()
        }, 100)
        setState('new')
        break
      case 'settings':
        microphone.disconnect()
        microphone = context.createMediaStreamSource(await getInput(selectedDevice!))
        microphone.connect(splitter)
        break
      case 'record':
        if (playback.recorder!.state === 'inactive') {
          channels.forEach(channel => channel.recorder!.start(1000))
          playback.recorder!.start(1000)
        } else {
          channels.forEach(channel => channel.recorder!.resume())
          playback.recorder!.resume()
        }
        durationHandle.current = setInterval(() => setDuration(x => x + 0.1), 100)
        break
        case 'pause':
          clearInterval(durationHandle.current!)
          channels.forEach(channel => channel.recorder!.pause())
          playback.recorder!.pause()
        break
      case 'finalize':
        clearInterval(durationHandle.current!)
        channels.forEach(channel => {
          channel.recorder!.stop()
          tracks.push({
            id: channel.id,
            blob: new Blob(channel.buffer, { type: channel.recorder!.mimeType }),
            type: 'multitrack'
          })
        })
        playback.recorder!.stop()
        playback.blob = new Blob(playback.buffer, { type: playback.recorder!.mimeType })
        player.current!.src = URL.createObjectURL(playback.blob)
        player.current!.onended = () => player.current!.currentTime = 0.01;
        player.current!.currentTime = 0.01;
        break
      case 'upload':
        await AuphonicAPI.upload(tracks,  { ...episode!, track: sessions.find(session => session.id === episode!.album)!.chapters + 1 })
        setState('done')
    }
  })()}, [state, selectedDevice, forceUpdate])
  
  return (
    <div className="App">
      <audio ref={player} crossOrigin='anonymous'/>
      {state !== 'welcome' && <div className='row' style={{ position: 'absolute', top: '8px', right: '16px', justifyContent: 'flex-start' }}>
        <img className='shadowedImage' alt='YK8 Logo' src={logo} height='50px' style={{ marginLeft: '8px' }} />
        <RoundButton size={60} onClick={() => window.location.reload()} icon={MaterialIcons.MdHome} />
        {state === 'new' && <RoundButton size={60} onClick={() => setState('settings')} icon={MaterialIcons.MdSettings} />}
      </div>}
      {(() => {
        switch (state) {
          case 'welcome':
            return <>
              <img className='shadowedImage' alt='YK8 Logo' src={logo} height='80px' style={{ position: 'absolute', top: '24px' }} />
              <div className='glass container'>
                <label>מערכת העלאת פודקאסטים</label>
                <div className='row'>
                  <button onClick={() => setState('initialize')}>כניסה</button>
                </div>
              </div>
            </>
          case 'new':
            return <>
              <div className='glass container' style={{ width: episode ? '40vw' : '75vw' }}>
                <label>בחר פודקאסט</label>
                <div className='row' style={{ overflowX: 'scroll', alignContent: 'space-between', paddingBottom: '12px' }}>
                  {sessions.map(session => <div className='container' style={{ marginLeft: '16px' }}>
                    <img className='shadowedCard' src={`https://yk8recordings.blob.core.windows.net/images/${session.id}.jpg`} alt={session.title} style={{ borderRadius: '16px', opacity: session.id === episode?.album ? '100%' : '60%' }} height={episode ? 100 : 200} onClick={() => {
                      setEpisode({ ...episode!, album: session.id, track: session.chapters + 1 })
                    }} />
                  </div>)}
                </div>
                {episode && <>
                  <span style={{ fontSize: '24px' }}>{sessions.find(session => session.id === episode!.album)!.title}</span>
                  <span style={{ fontSize: '16px' }}>{sessions.find(session => session.id === episode!.album)!.description}</span>
                </>}
              </div>
              {episode && <>
                <div className='glass container' style={{ width: '50vw' }}>
                  <label>פרטי הפרק</label>
                  <div className='row'>
                    <span style={{ marginInlineStart: '4px', marginInlineEnd: '8px', fontSize: '20px' }}>{`פרק ${episode.track}:`}</span>
                    <input type='text' placeholder='כותרת הפרק' value={episode!.title} onChange={e => setEpisode({ ...episode!, title: e.target.value })} />
                  </div>
                  <div className='row'>
                    <textarea placeholder='תיאור הפרק' value={episode!.summary} onChange={e => setEpisode({ ...episode!, summary: e.target.value })} rows={3} />
                  </div>
                </div>
                {episode.title && episode.summary && <div className='row'>
                  <RoundButton icon={MaterialIcons.MdFiberManualRecord} color='red' onClick={() => setState('record')} />
                </div>}
              </>}
            </>
          case 'settings':
            return <>
              <div className='glass container' style={{ width: '40vw' }}>
                <label>הגדרות</label>
                <div className='row'>
                  <span style={{ flex: 0, marginInlineStart: '4px', marginInlineEnd: '8px', fontSize: '28px' }}><MaterialIcons.MdMicExternalOn /></span>  
                  <select value={selectedDevice!} onChange={handleChange}>
                    {devices.filter(device => device.kind === 'audioinput').map(device => <option key={device.deviceId} value={device.deviceId} label={device.label} />)}
                  </select>
                </div>
              </div>
              <div className='row'>
                <RoundButton icon={MaterialIcons.MdDone} onClick={() => setState('new')} />
              </div>
            </>
          case 'record':
          case 'pause':
            return <>
              <div className='row' style={{ width: '50vw' }}>
                {state === 'record' && <div className='glass container' style={{ height: 450, flex: 1 }}>
                  <div className='container' style={{ flex: 1, width: '100%' }}>
                    <div style={{ backgroundColor: 'transparent', width: '100%', flex: 1 - (Math.max(...(Array(waveforms[0].length).fill(0).map((value, index) => waveforms[0][index]))) - 128) / 128 }} />
                    <div style={{ backgroundColor: 'red', width: '100%', flex: (Math.max(...(Array(waveforms[0].length).fill(0).map((value, index) => waveforms[0][index]))) - 128) / 128 }} />
                  </div>
                  <RoundButton icon={channels[0].gain.gain.value > 0.001 ? MaterialIcons.MdVolumeUp : MaterialIcons.MdVolumeOff} size={50} color={channels[0].gain.gain.value > 0.001 ? 'white' : 'red'} onClick={() => player.current?.paused && toggleMute(channels[0])}/>
                  <span style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: 16, height: 40 }}>{channels[0].label}</span>
                </div>}
                <div className='container'>
                  <div className='glass container' style={{ width: '40vw' }}>
                    <label>הקלטת פודקאסט</label>
                    <img className='shadowedCard' src={`https://yk8recordings.blob.core.windows.net/images/${episode?.album}.jpg`} alt={sessions.find(session => session.id === episode?.album)!.title} style={{ borderRadius: '16px', marginBottom: '8px' }} height={200} />
                    <span style={{ fontSize: '24px' }}>{sessions.find(session => session.id === episode!.album)!.title}</span>
                    <span style={{ fontSize: '28px' }}>פרק {episode!.track}: {episode!.title}</span>
                    <span style={{ fontSize: '16px' }}>{episode!.summary}</span>
                  </div>
                  <div className='glass row' style={{ width: 'initial' }}>
                    <span style={{ marginInline: '16px' }}>{Math.floor(duration / 60).toString().padStart(2, '0')}:{Math.floor(duration % 60).toString().padStart(2, '0')}</span>
                    {state === 'record'
                      ? player.current?.paused && <RoundButton icon={MaterialIcons.MdPause} size={50} onClick={() => setState('pause')} />
                      : <RoundButton icon={MaterialIcons.MdFiberManualRecord} size={50} color='red' onClick={() => setState('record')} />
                    }
                    {state === 'record' && (!player.current!.paused || (!player.current!.ended && duration <= 60)) && <RoundButton icon={MaterialIcons.MdAudiotrack} size={50} onClick={() => player.current?.paused && startIntro()} color={!player.current!.paused && duration % 1 < 0.5 ? 'lightgreen' : '#fffd'} />}
                    {state === 'record' && player.current!.paused && (player.current!.ended || duration > 60) && <RoundButton icon={MaterialIcons.MdDone} size={50} onClick={() => setState('finalize')} />}
                  </div>
                </div>
                {state === 'record' && <div className='glass container' style={{ height: 450, flex: 1 }}>
                  <div className='container' style={{ flex: 1, width: '100%' }}>
                    <div style={{ backgroundColor: 'transparent', width: '100%', flex: 1 - (Math.max(...(Array(waveforms[1].length).fill(0).map((value, index) => waveforms[1][index]))) - 128) / 128 }} />
                    <div style={{ backgroundColor: 'red', width: '100%', flex: (Math.max(...(Array(waveforms[1].length).fill(0).map((value, index) => waveforms[1][index]))) - 128) / 128 }} />
                  </div>
                  <RoundButton icon={channels[1].gain.gain.value > 0.001 ? MaterialIcons.MdVolumeUp : MaterialIcons.MdVolumeOff} size={50} color={channels[1].gain.gain.value > 0.001 ? 'white' : 'red'} onClick={() => player.current?.paused && toggleMute(channels[1])}/>
                  <span style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: 16, height: 40 }}>{channels[1].label}</span>
                </div>}
              </div>
            </>
          case 'finalize':
            return <>
              <div className='container'>
                <div className='glass container' style={{ width: '40vw' }}>
                  <label>סקירה לפני העלאה</label>
                  <img className='shadowedCard' src={`https://yk8recordings.blob.core.windows.net/images/${episode?.album}.jpg`} alt={sessions.find(session => session.id === episode?.album)!.title} style={{ borderRadius: '16px', marginBottom: '8px' }} height={200} />
                  <span style={{ fontSize: '24px' }}>{sessions.find(session => session.id === episode!.album)!.title}</span>
                  <span style={{ fontSize: '28px' }}>פרק {episode!.track}: {episode!.title}</span>
                  <span style={{ fontSize: '16px' }}>{episode!.summary}</span>
                </div>
                <div className='glass row' style={{ width: '40vw', direction: 'ltr' }}>
                  <RoundButton icon={MaterialIcons.MdCloudUpload} size={50} onClick={() => setState('upload')} />
                  <RoundButton icon={player.current?.paused ? MaterialIcons.MdPlayArrow : MaterialIcons.MdPause} size={50} onClick={() => player.current?.paused ? player.current?.play() : player.current?.pause()} />
                  <span style={{ marginInline: '12px', fontSize: '16px' }}>{(Math.floor(player.current?.currentTime! / 60) || 0).toString().padStart(2, '0')}:{(Math.floor(player.current?.currentTime! % 60) || 0).toString().padStart(2, '0')}</span>
                  <div className='shadowedCard' style={{ display: 'flex', flex: 1, justifyItems: 'center', backgroundColor: '#0004', height: '8px', margin: '4px', borderRadius: '4px' }} onMouseDown={e => player.current!.currentTime = (e.clientX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.getBoundingClientRect().width * duration}>
                    <div className='shadowedCard' style={{ flex: player.current?.currentTime! / duration, backgroundColor: '#fff4', height: '8px', borderRadius: '4px' }} />
                    <div className='shadowedCard' style={{ position: 'relative', backgroundColor: '#eee', height: '16px', width: '16px', right: '8px', bottom: '4px', borderRadius: '8px' }} />
                  </div>
                  <span style={{ marginInline: '12px', fontSize: '16px' }}>{(Math.floor(duration / 60) || 0).toString().padStart(2, '0')}:{(Math.floor(duration % 60) || 0).toString().padStart(2, '0')}</span>
                </div>
              </div>
            </>
          case 'upload':
            return <div className='glass container'>
              <label>מעלה לשרת</label>
              <span>אנא המתן...</span>
            </div>
          case 'done':
            return <div className='glass container'>
              <label>הפודקאסט הועלה בהצלחה!</label>
              <span>הפודקאסט מעובד כעת, ויהיה זמין להאזנה בתוך כשעה.</span>
            </div>
        }
      })()}
    </div>
  )
}

export default App