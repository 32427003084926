import { adalApiFetch, url } from './AuthConfig';

export default class APIClient {
    static select = async (query = '', resultType: 'json' | 'list' | 'single' = 'json') => {
        var options = {
            method: 'GET',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        let result: any;
        try {
            await Promise.race([
                adalApiFetch(fetch, url + query, options)
                    .then(async response => {
                        result = await response.json();
                        if (response.status !== 200)
                            throw new Error('SELECT failed - ' + result.error.message);
                        return result;
                    }),
                new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]);
            switch (resultType) {
                case 'json': return Promise.resolve(result);
                case 'list': return Promise.resolve(result.value);
                case 'single': return Promise.resolve(result.value[0]);
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    static insert = async (query: string, body: object) => {
        var options = {
            method: 'POST',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        };
        try {
            return await Promise.resolve(await Promise.race([
                adalApiFetch(fetch, url + query, options)
                    .then(async response => {
                        if (response.status !== 204)
                            throw new Error('INSERT failed - ' + (await response.json()).error.message);
                        return response;
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    static update = async (query: string, body: object) => {
        var options = {
            method: 'PATCH',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        };
        try {
            return await Promise.resolve(await Promise.race([
                adalApiFetch(fetch, url + query, options)
                    .then(async response => {
                        if (response.status !== 204)
                            throw new Error('UPDATE failed - ' + (await response.json()).error.message);
                        return response;
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    static delete = async (query: string) => {
        var options = {
            method: 'DELETE',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        try {
            return await Promise.resolve(await Promise.race([
                adalApiFetch(fetch, url + query, options)
                    .then(async response => {
                        if (response.status !== 204)
                            throw new Error('DELETE failed - ' + (await response.json()).error.message);
                        return response;
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}