import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

const clientId = '0d76eddc-1b64-41ba-a883-4b0a6124827b';

export const data = {
  'graph': {
    clientId: clientId,
    redirectUrl: 'https://podcasts.yk8.org.il',
    issuer: 'https://login.microsoftonline.com/1a3879e6-c0bf-4b27-ae1f-8ec7c91d0c8f/v2.0',
    authorizationEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
    tokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
    scopes: [
    'openid',
    'offline_access',
    'profile',
    'User.ReadWrite',
    'People.Read'
    ],
    additionalParameters: {
      prompt: 'select_account' as 'select_account'
    }
  },
  'cds': { 
    token_endpoint: 'https://login.microsoftonline.com/1a3879e6-c0bf-4b27-ae1f-8ec7c91d0c8f/oauth2/token',
    grant_type: "client_credentials",
    client_id: clientId,
    resource: 'https%3A%2F%2Fyk8.api.crm4.dynamics.com', // percent encoding is required
    client_secret: '4M%7E_H0Mt9ksIw%7E119JM_qSkKOSBt.z9.da' // percent encoding is required
  },
  'azure_blob': {
    connection_string: 'sp=racwdl&st=2021-07-31T21:00:00Z&se=2050-12-31T21:59:59Z&spr=https&sv=2020-08-04&sr=c&sig=nToP4qNsyjgvcbX95sajAPmQLTHHEPWs7MFhw2mPwYI%3D'
  },
  'auphonic': {
    connection_string: 'bWVkaWFAeWs4Lm9yZy5pbDpLIXJ5YXQkaG0wbmE='
  },
  'api': {
    names: {
      graph: 'Microsoft Graph',
      cds: 'Common Data Service'
    },
    endpoints: {
      graph: 'https://graph.microsoft.com/beta',
      cds: 'https://yk8.api.crm4.dynamics.com/api/data/v9.1',
      azure_blob: 'https://yk8recordings.blob.core.windows.net',
      auphonic: 'https://auphonic.com/api'
    }
  }
}

const adalConfig = {
  tenant: '1a3879e6-c0bf-4b27-ae1f-8ec7c91d0c8f',
  clientId: clientId,
  endpoints: {
    api: 'https://yk8.crm4.dynamics.com',
  },
  cacheLocation: 'localStorage' as 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch: (input: string, init: any) => Promise<any>, url: string, options: any) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const url = "https://yk8.api.crm4.dynamics.com/api/data/v9.1";

export default data;