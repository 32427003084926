import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { runWithAdal } from 'react-adal';
import { authContext } from './api/AuthConfig';

const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {
  //const ProtectedApp = withAdalLoginApi(App, <></>, <></>)
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}, DO_NOT_LOGIN);